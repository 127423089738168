









import Vue from 'vue';

export default Vue.extend({
  name: 'AndroidRedirect',
  data() {
    return {
      redirectLink: `uvacovidresponse://cognito?${Object.keys(this.$route.query)
        .map((key) => key + '=' + this.$route.query[key])
        .join('&')}`,
    };
  },
});
